import moment from "moment/moment";

export function getBookingNotificationCart(orderId, booking)
{
    const blockedAt = booking.blocked_at;

    return {
        orderId: orderId,
        id: booking.id,
        code: booking.code,
        blockedAt: moment.utc(blockedAt),
        expirations: [5, 0],
        notifiedMinutes: localStorage.getItem(`booking-draft-notification-cart-${booking.id}`) ? JSON.parse(localStorage.getItem(`booking-draft-notification-cart-${booking.id}`)) : []
    }
}

export function getBookingNotificationCartFormOrder(salesOrder)
{
    if(!salesOrder) {
        return [];
    }
    let items = salesOrder.items ;

    if(items?.length)
    {
        let notifications = [];
        for(let i = 0; i < items.length; i++) {
            notifications.push(
                getBookingNotificationCart(salesOrder.id, items[i].salesOrderable)
            )
        }

        return notifications;
    }

    return [] ;
}

export function getBookingNotificationCartFormOrders(salesOrders)
{
    if(salesOrders?.length)
    {
        let notifications = [];
        for (let j = 0; j< salesOrders?.length; j++) {
            notifications = [...notifications, ...getBookingNotificationCartFormOrder(salesOrders[j])] ;
        }

        return notifications ;
    }


    return [];
}