import qs from "qs";
import axios from "axios";
import Jsona from "jsona";
import {cloneDeep} from "lodash";
import bookingsService from "@/store/services/bookings-service";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };
  return axios.get(`${url}/spots`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

/**
 * Récupérer une emplacement avec sont id.
 *
 * @param params  '{id: 'identitiant'}'
 * @returns {Promise<TJsonaModel | Array<TJsonaModel>>}
 */
function get({ id, params = {} }) {
  params.include = "organization,category,spotType,other_equipments,createdBy";

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios
    .get(
      `${url}/spots/${id}`,
      options
    )
    .then((response) => {
      let spot = jsona.deserialize(response.data);
      delete spot?.links;
      return spot;
    });
}

function add(spot) {
  const payload = jsona.serialize({
    stuff: spot,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/spots`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(spot) {
  const payload = jsona.serialize({
    stuff: spot,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/spots/${spot.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/spots/${id}`, options);
}

function clean(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/spots/${id}/clean?include=organization,category,spotType,other_equipments,createdBy`,
      {},
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function dirty(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/spots/${id}/dirty?include=organization,category,spotType,other_equipments,createdBy`,
      {},
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}



function getUnavailableDates({ id, dateRange, bookingId = null }) {
  const [start_date,end_date] = dateRange

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    params : {
      start_date ,
      end_date,
      booking_id: bookingId
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/spots/${id}/unavailables-dates`, options).then((response) => {
    return response.data?.data ?? [];
  });
}

function getSpotCartStatus(spotId)
{
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/spots/${spotId}/state-locked-in-draft-status`, options).then((response) => {
    return response.data?.locked_in_draft ?? null;
  });
}

/**
 * Determine si un spot deja recuperer contient un mot cle ou non
 *
 * @param {Object} spot
 * @param {string} keyword
 *
 * @return boolean
 */
function searchInSpot(spot, keyword)
{
  keyword = keyword?.trim().toLowerCase();
  if(spot.excerpt)
  {
    return spot.name.trim().toLowerCase().includes(keyword) ||
        spot.code.trim().toLowerCase().includes(keyword) ||
        spot.excerpt.trim().toLowerCase().includes(keyword);
  }

  return spot.name.trim().toLowerCase().includes(keyword) ||
      spot.code.trim().toLowerCase().includes(keyword);
}

/**
 * Rechercher dans un tableau d'emplacement deja recuperer pour filtrer un peu plus.
 *
 * @param {Array} spots
 * @param {string} keyword
 * @param {boolean} isFiltered
 */
export function searchAndGetResults(spots, keyword, isFiltered = false)
{
  if(!spots?.length) {
    return [];
  }

  if(!keyword?.length)
  {
    if(isFiltered)
    {
      // let _spots = spots?.filter(spot => spot?.bookings?.length);
      //if(_spots.length) {
        return spots ;
      //}
    }

    return spots;
  }

  let results = [];
  spots.forEach(spot =>
  {
    let newSpot = cloneDeep(spot);
    // si le spots s'appelle marika, on le prend
    if(searchInSpot(spot, keyword))
    {
      let bookings = bookingsService.filterBookingsByCustomerOrCode(newSpot.bookings, keyword);
      // si les booking contiennent au moins un marika ou un code, on prend marika ou le code seule
      if(bookings.length) {
        newSpot.bookings = bookings ;
      }
      else {
        //
      }
      // sinon tout
      results.push(newSpot);
    }
    // le spots ne s'appelle pas marika
    else
    {
      let bookings = bookingsService.filterBookingsByCustomerOrCode(newSpot.bookings, keyword);
      // si les booking contiennent au moins un marika ou un code, on prend marika ou le code seule
      if(bookings.length)
      {
        newSpot.bookings = bookings ;
        results.push(newSpot) ;
      }
      // sinon rien de rien
      else {
        //
      }
    }
  }) ;

  return results;
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  clean,
  dirty,
  getUnavailableDates,
  searchInSpot,
  searchAndGetResults,
  getSpotCartStatus
};
