<template>
  <div class="step-body step-1">
    <!-- start_at, end_at -->
    <div class="form-wrapper width_1">
      <base-input class="form-wrapper full">
        <el-date-picker
          v-model="selectedDateRange"
          type="daterange"
          start-placeholder="Date de début"
          end-placeholder="Date de fin"
          format="dd MMM yyyy"
          value-format="yyyy-MM-dd"
          :min="new Date()"
          :clearable="false"
          :disabled-date="disableSpecificDates"
          :picker-options="{ disabledDate: disableSpecificDates, cellClassName: spotNotAvailableClass }"
          @change="onDateSelected"
        />
      </base-input>
      <validation-error
        v-if="isSpotUnavailable"
        :errors="[$t('BOOKINGS.NOT_AVAILABLE_ERROR')]"
      />
      <validation-error :errors="apiValidationErrors.start_at" />
      <validation-error v-if="this.booking.start_at === this.booking.end_at" :errors="[this.$t('BOOKINGS.DATE_RANGE_ALERT')]"/>
    </div>

    <!-- adult capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_ADULT_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_ADULT_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_ADULT_CAPACITY')"
          v-model="booking.adults_count"
          @change="onAdultCapacityChanged"
        >
          <el-option
            v-for="n in Array.from({ length: maxAdults }, (_, i) => i + 1)"
            :key="n"
            :value="n"
            :label="n"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.adults_count" />
    </div>

    <!-- children capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_CHILDREN_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
          v-model="booking.children_count"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in maxChildren + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.children_count" />
    </div>

    <!-- pets capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_PETS_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_PETS_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_PETS_CAPACITY')"
          v-model="booking.pets_count"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in maxPets + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.pets_count" />
    </div>

    <div class="form-wrapper width_1">
      <label class="pers-count">
        {{ $t("BOOKINGS.CAPACITY") }} :
        {{ booking.adults_count + booking.children_count }} pers
      </label>
    </div>

    <!-- beds qty -->
    <div class="form-wrapper width_1" v-if="!displayBedRoomQuantityField">
      <base-input :label="`${$t('SPOTS.BEDS_TOTAL_QTY')}`">
        <el-select
          :label="$t('SPOTS.BEDS_TOTAL_QTY')"
          :placeholder="$t('SPOTS.BEDS_TOTAL_QTY')"
          v-model="booking.beds_qty"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in spot.beds_total_qty + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.beds_qty" />
    </div>

    <!-- rooms qty -->
    <div
      class="form-wrapper width_1"
      v-if="!displayBedRoomQuantityField"
    >
      <base-input
        :label="`${$t('SPOTS.ROOMS_QTY')}`"
        :placeholder="$t('SPOTS.ROOMS_QTY')"
        type="number"
        :max="10"
        :min="0"
        v-model="booking.rooms_qty"
        @change="onFormChanged"
      >
      </base-input>

      <validation-error :errors="apiValidationErrors.rooms_qty" />
    </div>

    <div class="form-wrapper width_1">
      <base-input :label="`${$t('SPOTS.CHANGE_RATES')}`">
        <el-select
          :label="$t('SPOTS.CHANGE_RATES')"
          :placeholder="$t('SPOTS.CHANGE_RATES')"
          v-model="booking.rate"
          @change="onFormChanged"
        >
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.rate" />
    </div>

    <div class="form-wrapper width_1" v-if="isLandOrSeasonalFormType">
      <base-input :label="`${$t('SPOTS.EQUIPMENT_SELECTION')}`">
        <allowed-equipment-selector
          :value="booking.equipment"
          :options-value="spot.allowed_equipment"
          @valueChanged="(value) => (booking.equipment = value)"
          allowNone
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.equipment" />
    </div>

    <!-- equipment length -->
    <div
      class="form-wrapper width_1"
      v-if="
        isLandOrSeasonalFormType &&
        booking.equipment &&
        booking.equipment !== TENT
      "
    >
      <base-input
        type="text"
        :min="0"
        :max="maxEquipmentLength"
        :label="$t('SPOTS.EQUIPMENT_LENGTH')"
        :placeholder="$t('SPOTS.EQUIPMENT_LENGTH')"
        :name="`'${$t('SPOTS.EQUIPMENT_LENGTH')}'`"
        :rules="equipmentLengthValidationRules"
        v-model="booking.equipment_length"
        @change="onFormChanged"
      >
        <template #label>
          <label class="form-control-label" for="">
            {{ $t("SPOTS.EQUIPMENT_LENGTH") }}
            <span v-if="maxEquipmentLength" class="h5 text-muted font-italic">
              ({{
                $t("SPOTS.MAX_LENGTH", { length: maxEquipmentLength ?? 0 })
              }})
            </span>
          </label>
        </template>
      </base-input>
      <validation-error :errors="apiValidationErrors.equipment_length" />
    </div>

    <div class="form-wrapper width_1-2" v-if="isLandOrSeasonalFormType">
      <base-input :label="`${$t('SPOTS.DRIVER_EXTENSION')}`">
        <el-select
          :label="$t('SPOTS.DRIVER_EXTENSION')"
          :placeholder="$t('SPOTS.DRIVER_EXTENSION')"
          v-model="booking.driver_extensions"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in spot.driver_extensions + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.rate" />
    </div>

    <div class="form-wrapper width_1-2" v-if="isLandOrSeasonalFormType">
      <base-input :label="`${$t('SPOTS.PASSENGER_EXTENSION')}`">
        <el-select
          :label="$t('SPOTS.PASSENGER_EXTENSION')"
          :placeholder="$t('SPOTS.PASSENGER_EXTENSION')"
          v-model="booking.passenger_extensions"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in spot.passenger_extensions + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.passenger_extensions" />
    </div>

    <div class="form-wrapper width_1" v-if="isLandOrSeasonalFormType">
      <base-input :label="`${$t('SPOTS.ELECTRICITY')}`">
        <electricity-selector
          :options-value="spot.electricity"
          :value="booking.electricity"
          @valueChanged="(value) => (booking.electricity = value)"
          allow-none
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.electricity" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('BOOKINGS.SPECIAL_REQUEST')"
        :placeholder="$t('BOOKINGS.SPECIAL_REQUEST')"
      >
        <el-input type="textarea" v-model="booking.special_request"></el-input>
      </base-input>
      <validation-error :errors="apiValidationErrors.special_request" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('BOOKINGS.INTERNAL_NOTE')"
        :placeholder="$t('BOOKINGS.INTERNAL_NOTE')"
      >
        <el-input type="textarea" v-model="booking.comment"></el-input>
      </base-input>
      <validation-error :errors="apiValidationErrors.comment" />
    </div>

    <div class="form-wrapper width_1 summary">
      <label class="pers-count"> {{ $t("SPOTS.OPTIONS") }} </label>
      <div class="mt-2">
        <base-checkbox v-model="booking.is_spot_blocked">
          {{ $t("BOOKINGS.BLOCK_THIS_SPOT") }}
        </base-checkbox>
      </div>
    </div>

    <div class="form-wrapper width_1 buttons-group">
      <el-button
        :disabled="isSpotUnavailable"
        class="continue"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t("COMMON.CONTINUE") }}
      </el-button>
      <!-- <el-button class="outline" type="secondary" @click="handleSubmit">
        {{ $t("COMMON.SAVE_FOR_LATER") }}
      </el-button> -->
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Header,
  Image,
  Row,
  Step,
  Steps,
  Select,
  Option,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import "flatpickr/dist/flatpickr.css";
import ValidationError from "@/components/ValidationError";
import OrganizationSelector from "@/components/OrganizationSelector";
// import EquipmentSelector from "@/components/EquipmentSelector";
import defaultBooking from "../../defaultBooking";
import defaultSpot from "../../../../SpotModule/SpotManagement/defaultSpot";
import AllowedEquipmentSelector from "@/components/AllowedEquipmentSelector.vue";
import ElectricitySelector from "@/components/ElectricitySelector.vue";
import {
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_PROPERTY,
  BOOKING_FORM_TYPE_READY_TO_CAMP,
  BOOKING_FORM_TYPE_SEASONAL,
} from "@/constants/spotCategories";
import { TENT } from "@/constants/allowedEquipments";
import swal from "sweetalert2";
import { SPOT_STATUS_NOT_AVAILABLE } from "@/constants/common";
import { getSpotState } from "@/helpers";
import moment from "moment/moment";


export default {
  name: "booking-spot-selection",

  components: {
    OrganizationSelector,
    ValidationError,
    ElectricitySelector,
    AllowedEquipmentSelector,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      default: defaultBooking,
      description: "Booking object",
    },
    spot: {
      type: Object,
      default: defaultSpot,
      description: "Spot object",
    },
  },

  data() {
    this.fetchBookingList();

    let selectedDateRange = [];

    if (this.bookingData.start_at) {
      selectedDateRange = [
        this.bookingData.start_at,
        this.bookingData.end_at
      ];
    }

    this.bookingData.adults_count = (this.bookingData.adults_count <= 0) ? 1 : this.bookingData.adults_count;

    const maxEquipmentLength = this.spot.equipment_length ?? 0;

    return {
      bookingsDates: [],
      booking: this.bookingData,
      selectedDateRange: selectedDateRange,
      bookingList: [],
      maxAdults: this.spot.adults_capacity ?? 0,
      maxChildren: this.spot.children_capacity ?? 0,
      maxPets: this.spot.pets_capacity ?? 0,
      maxEquipmentLength: maxEquipmentLength,
      showModal: false,
      showBookingCheckModal: false,
      loading: false,
      formErrors: null,
      BOOKING_FORM_TYPE_LAND,
      BOOKING_FORM_TYPE_PROPERTY,
      TENT,
      equipmentLengthValidationRules: `numeric|min_value:0|max_value:${maxEquipmentLength}`,
      disabledDates: [],
      bookingPricingChanged: false,
    };
  },

  async created() {
   await this.getUnavailableDates()
  },

  mounted() {},

  computed: {
    shouldIDisplayBedsQuantityField() {
      return (
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_LAND &&
        this.spot.category.booking_form_type !==
          BOOKING_FORM_TYPE_READY_TO_CAMP &&
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_SEASONAL
      );
    },
    displayBedRoomQuantityField() {
      return (
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_LAND &&
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_READY_TO_CAMP 
      );
    },
    isSeasonalFormType() {
      return (
        this.spot.category.booking_form_type === BOOKING_FORM_TYPE_SEASONAL
      );
    },
    isLandFormType() {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND;
    },
    isLandOrSeasonalFormType() {
      return this.isLandFormType || this.isSeasonalFormType;
    },
    isSpotUnavailable() {
      return this.spot.status === SPOT_STATUS_NOT_AVAILABLE;
    },
    state (){
      return getSpotState(this.spot)
    }
  },

  methods: {
    async handleSubmit() {
      if (this.isSpotUnavailable || !this.canOrderSpot()) {
        return;
      }

      if(this.booking.start_at === this.booking.end_at)
      {
        this.$notify({
          type: "warning",
          message: this.$t("BOOKINGS.DATE_RANGE_ALERT"),
        });
        return;
      }

      try
      {
        swal.showLoading();
        this.loading = true;

        let spotPricing = this.spot.pricing?.total ;
        if(this.bookingPricingChanged)
        {
          let params = {
            filter: {
              availability_date_range: [
                moment(this.booking.start_at).toISOString(),
                moment(this.booking.end_at).toISOString()
              ],
              adults_capacity: this.booking.adults_count
            }
          };

          await this.$store.dispatch("spots/get", {id: this.spot.id, params: params});
          let spotWithPricing = this.$store.getters["spots/spot"] ;

          spotPricing = spotWithPricing?.pricing?.total ;
        }

        if (!spotPricing) {
          this.$notify({
            type: "danger",
            message: this.$t("SPOTS.NO_PRICING_FOUND_FOR_SPOT"),
          });

          swal.close();
          return;
        }
        await this.sendInformation();
      }
      catch (error)
      {
        console.error(error);
        this.formErrors = error?.response?.data?.errors;
      }
      finally {
        swal.close();
        this.loading = false;
      }
    },

    async sendInformation()
    {
      const bookingData = cloneDeep(this.booking);

      bookingData.start_at = this.$convertDateToUtc(bookingData.start_at);
      bookingData.end_at = this.$convertDateToUtc(bookingData.end_at);

      if (!bookingData.customer?.id) {
        delete bookingData.customer;
      }

      if (!bookingData.spot?.id) {
        bookingData.spot.id = this.spot.id;
      }

      delete bookingData.order;

      if (bookingData.id) {
        delete bookingData.createdBy;
        delete bookingData.orderItem;

        await this.$store.dispatch("bookings/update", bookingData);
      } else {
        await this.$store.dispatch("bookings/add", bookingData);
      }

      const createdBooking = await this.$store.getters["bookings/booking"];
      this.booking.id = createdBooking.id;

      this.$emit("draftBookingCreated", createdBooking);
    },

    async fetchBookingList() {
      if (!this.spot) {
        return false;
      }

      try {
        this.loading = true;
        const today = new Date();
        const afterThreeMonths = new Date();
        afterThreeMonths.setMonth(today.getMonth() + 3);
        let params = {
          filter: {
            ...{
              spot_id_in: [this.spot.id],
              date_range: [today, afterThreeMonths],
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };

        if (this.booking?.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.booking?.organization?.id,
            },
          };
        }
        await this.$store.dispatch("bookings/list", params);
        this.bookingList = this.$store.getters["bookings/list"];
        this.loading = false;
      } catch (error) {
        console.warn(error);
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
      this.getUnavailableDates(this.spot.id,[this.booking.start_at , this.booking.end_at]);
    },
    onDateSelected(range) {
      this.booking.start_at = range[0];
      this.booking.end_at = range[1];
      this.bookingPricingChanged = true;
      this.onFormChanged();
    },
    onAdultCapacityChanged(capacity) {
      this.booking.adults_count = capacity;
      this.bookingPricingChanged = true;
      this.onFormChanged();
    },

    /**
     * Mettre a 0 l'heure d'une date avec heure.
     *
     * @param {Date} date
     * @returns {Date}
     */
    setDateTimeComponentToZero(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);

      return date;
    },

    async getUnavailableDates(id = null , dateRange = null)
    {
      try
      {
        id ??=  this.spot.id
        console.log("id =>",id," dateRange => ",dateRange);

        if(id)
        {
          dateRange ??= this.selectedDateRange;

          await this.$store.dispatch("spots/getUnavailableDates", {id, dateRange});
          let disabledDates = this.$store.getters["spots/unavailableDates"];

          if(disabledDates?.length) {
            this.disabledDates = disabledDates.map(dd => dd.split('T')[0]) ;
          }

          return this.disabledDates;
        }

        return [];
      }
      catch (error) {
        console.error(error);
      }
    },

    spotNotAvailableClass(date)
    {
      if(date < this.setDateTimeComponentToZero(new Date())) {
        return '';
      }

      const formattedDate = date.toISOString().split('T')[0];

      return this.disabledDates.includes(formattedDate) ? "not-available" : "";
    },

    disableSpecificDates(date)
    {
      // Convertir la date en format 'YYYY-MM-DD' pour la comparer à la liste
      const formattedDate = date.toISOString().split('T')[0];

      return this.disabledDates.includes(formattedDate) || date < this.setDateTimeComponentToZero(new Date());
    },

    setModal(config) {
      config = {
        ...{
          title: null,
          html: null,
          type: "warning",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: false,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText: this.$t("COMMON.CLOSE"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        },
        ...config
      };
      return swal.fire(config);
    },

    canOrderSpot()
    {
      let [start_date, end_date] = [
        moment(this.booking.start_at).toISOString().split('.')[0],
        moment(this.booking.end_at).toISOString().split('.')[0]
      ];

      const disabledDates = this.disabledDates;

      let errors = [];

      if (disabledDates) {
        // Vérifie si `start` ou `end` est dans les `unavailableDates`
        if (disabledDates.includes(start_date)) {
          errors.push(`L'emplacement n'est pas disponible le ${this.$formatDate(start_date, "DD-MM-YYYY")}`);
        } else if (disabledDates.includes(end_date)) {
          errors.push(`L'emplacement n'est pas disponible le ${this.$formatDate(end_date, "DD-MM-YYYY")}`);
        } else {
          errors = [];
        }
      }

      if (errors.length) {
        this.setModal({html: this.$t("BOOKINGS.DATES_NOT_AVAILABLE")});
      }

      return !errors.length;
    }

  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    bookingData: {
      handler: function (bookingData) {
        if (bookingData) {
          this.booking = {
            ...this.booking,
            ...cloneDeep(bookingData),
          };

          this.booking.adults_count =
            bookingData.adults_count < 2 ? 2 : bookingData.adults_count;
          this.booking.children_count = bookingData.children_count;
          this.booking.pets_count = bookingData.pets_count;
        }
      },
      deep: true,
    },
    spot: {
      handler: function (spot) {
        if (spot) {
          this.fetchBookingList();

          this.maxAdults = spot.adults_capacity;
          this.maxChildren = spot.children_capacity;
          this.maxPets = spot.pets_capacity;
          this.maxEquipmentLength = spot.equipment_length;
          this.equipmentLengthValidationRules = `numeric|min_value:0|max_value:${spot.equipment_length}`;
        }
      },
      deep: true,
    },
  },
};
</script>
