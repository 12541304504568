<template>
  <div class="container-fluid">
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              :to="{ name: 'List Bookings' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("COMMON.BOOKINGS") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.BOOKING_LIST") }}</span>
              </li>
              <li>
                <span>{{ $t("COMMON.BOOKINGS") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <!-- shopping cart here -->
          <shopping-cart ref="cart" :key="renderKey * 200" />
        </div>
      </div>

      <booking-spot-list-table
        @onCreateBooking="openBookingCreateModal"
        @onViewBooking="openBookingModal"
        @onEditBooking="openEditBookingModal"
        @onOpenConfirmBookingModal="onOpenConfirmBookingModal"
        :filter-organization="globalOrganization.id"
        ref="spotList"
        :tab.sync="tab"
        :key="renderKey * 100"
      />

      <!-- region create booking -->
      <div
        v-if="isAddBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeBookingModal(false)">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.ADD_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-booking-component
              v-if="selectedSpotId"
              :spotId="selectedSpotId"
              @onViewBooking="openBookingModal"
              @onCloseBookingModal="closeBookingModal"
              @onOpenConfirmBookingModal="onOpenConfirmBookingModal"
              @onBookingChanged="onBookingChanged"
              :bookingData="bookingData"
            />
          </div>
        </div>
      </div>
      <!-- endregion -->

      <div
        v-if="isConfirmBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isConfirmBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeBookingModal(false)">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.CONFIRM_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button />
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <confirm-booking-component
              v-if="openOrder"
              :sales-order-id="openOrder.id"
              @onCloseBookingModal="closeBookingModal"
            />
          </div>
        </div>
      </div>

      <!-- region Modal de details d'une reservation depuis le calendier -->
      <div
        v-if="isViewBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'BOOKING_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeBookingModal(false)">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.VIEW_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-booking-component
              v-if="openBooking"
              :bookingId="openBooking.id"
              @onCheckOutBooking="checkOutBooking"
              @onCheckInBooking="checkInBooking"
              @onEditBooking="editBookingFromView"
              @onCancellingBooking="cancelBooking"
              @onOpenConfirmBookingModal="onOpenConfirmBookingModal"
            />
          </div>
        </div>
      </div>
      <!-- endregion -->

      <!-- region Modal pour modifier une reservation depuis le calendrier -->
      <div
        v-if="isEditBookingModalOpened"
        class="resizable-wrapper"
        :class="[isEditBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="backFromEditionToView">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.EDIT_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button />
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-booking-component
              v-if="isEditBookingModalOpened"
              :sales-order-id="this.openOrder.id"
              :booking-id="this.openBooking.id"
              @closeBookingModal="closeBookingModal"
              @bookingUpdated="handleAfterBookingUpdated"
              @customerUpdated="handleAfterBookingUpdated"
              @onOpenConfirmBookingModal="onOpenConfirmBookingModal"
              @onSalesOrderEditionCancelled="closeBookingModal"
            />
          </div>
        </div>
      </div>
      <!-- endregion -->
    </div>
    <select-an-organization-message v-else />
  </div>
</template>

<script>
import swal from "sweetalert2";
import {
  Button,
  Link,
  PageHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_CONFIRM,
  BOOKING_STATUS_GUARANTEED,
} from "@/constants/common";
import AddBookingComponent from "./components/AddBookingComponent.vue";
import ConfirmBookingComponent from "./components/ConfirmBookingComponent.vue";
import BookingSpotListTable from "./partials/BookingSpotListTable.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton.vue";
import ViewBookingComponent from "./components/ViewBookingComponent.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import { cloneDeep } from "lodash";
import ShoppingCart from "@/components/ShoppingCart";
import EditBookingComponent from "@/views/Pages/BookingModule/BookingManagement/components/EditBookingComponent.vue";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";
import { mapGetters } from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    EditBookingComponent,
    ShoppingCart,
    WrapperExpandButton,
    BookingSpotListTable,
    ConfirmBookingComponent,
    AddBookingComponent,
    ViewBookingComponent,
    NotificationSubscription,
    [Button.name]: Button,
    [PageHeader.name]: PageHeader,
    [Link.name]: Link,
    [BreadcrumbItem.name]: BreadcrumbItem,
    [Breadcrumb.name]: Breadcrumb,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  data() {
    const bookingId = this.$route.query.id;
    const spotId = this.$route.query.spotId;
    const orderId = this.$route.query.orderId;
    const action = this.$route.query.action;
    const startDate = this.$route.query.startDate;
    const endDate = this.$route.query.endDate;
    const tab = this.$route.query.tab ?? "list";
    let isViewBookingModalOpened = false;
    let isEditBookingModalOpened = false;
    let isAddBookingModalOpened = false;
    let isConfirmBookingModalOpened = false;
    let openBooking = null;
    let openOrder = null;
    let bookingData = {};

    openOrder = { id: orderId };

    if (startDate) {
      bookingData.start_at = startDate;
    }

    if (endDate) {
      bookingData.end_at = endDate;
    }

    if (action) {
      if (action === QUERY_ACTIONS_ADD) {
        isAddBookingModalOpened = true;
      } else if (action === QUERY_ACTIONS_CONFIRM && orderId) {
        isConfirmBookingModalOpened = true;

        openOrder = { id: orderId };
      } else {
        switch (action) {
          case QUERY_ACTIONS_VIEW:
            isViewBookingModalOpened = true;
            break;

          case QUERY_ACTIONS_EDIT:
            isEditBookingModalOpened = true;
            break;

          case QUERY_ACTIONS_CONFIRM:
            break;
        }

        openBooking = { id: bookingId };
      }
    }
    return {
      isViewBookingModalOpened: isViewBookingModalOpened,
      isEditBookingModalOpened: isEditBookingModalOpened,
      isAddBookingModalOpened: isAddBookingModalOpened,
      isConfirmBookingModalOpened,
      openBooking,
      openOrder,
      selectedSpotId: spotId,
      renderKey: 1,
      spotListrenderKey: 1,
      bookingData,
      searchCriteria: null,
      tab,
      isBookingUpdated: false,
      fromDetailsView: false,
    };
  },

  methods: {
    openBookingCreateModal(
      spotId,
      startDate = null,
      endDate = null,
      reRender = false,
      searchCriteria = null
    ) {
      this.searchCriteria = searchCriteria;

      this.selectedSpotId = spotId;
      this.closeBookingModal();
      this.isAddBookingModalOpened = true;

      let query = { spotId: spotId, action: QUERY_ACTIONS_ADD };

      if (searchCriteria) {
        let searchAdultCapacity = Number.parseInt(
          searchCriteria.adults_capacity
        );
        if (!isNaN(searchAdultCapacity)) {
          this.bookingData.adults_count = searchAdultCapacity;
        }

        // Preselection child count
        let searchChildCapacity = Number.parseInt(
          searchCriteria.children_capacity
        );
        if (!isNaN(searchChildCapacity)) {
          this.bookingData.children_count = searchChildCapacity;
        }

        // Preselection pets count
        let searchPetsCapacity = Number.parseInt(searchCriteria.pets_capacity);
        if (!isNaN(searchPetsCapacity)) {
          this.bookingData.pets_count = searchPetsCapacity;
        }
      }

      if (startDate) {
        query = { ...query, startDate };

        this.bookingData.start_at = startDate;
      }

      if (endDate) {
        query = { ...query, endDate };

        this.bookingData.end_at = endDate;
      }

      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        { spot: spotId },
        null,
        this.$router.resolve({
          name: "New Booking",
          query,
        }).href
      );
    },

    openBookingModal(booking, reRender = false) {
      this.closeBookingModal();
      this.openBooking = booking;
      this.openOrder = { id: booking.orderId };
      this.isViewBookingModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query: { id: this.openBooking.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    onOpenConfirmBookingModal(order, reRender = false) {
      this.closeBookingModal();
      this.openOrder = order;
      this.isConfirmBookingModalOpened = true;

      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query: { orderId: this.openOrder.id, action: QUERY_ACTIONS_CONFIRM },
        }).href
      );
    },

    backFromEditionToView() {
      this.isAddBookingModalOpened = false;
      this.isViewBookingModalOpened = true;
      this.isEditBookingModalOpened = false;
      if (this.isBookingUpdated) {
        this.renderKey++;
        this.isBookingUpdated = false;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: { id: this.openBooking.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    closeBookingModal(reRender = false) {
      this.isAddBookingModalOpened = false;
      this.isViewBookingModalOpened = false;
      this.isEditBookingModalOpened = false;
      this.isConfirmBookingModalOpened = false;
      this.openBooking = null;
      this.openOrder = null;

      if (reRender || this.isBookingUpdated) {
        console.log("render++");
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query: { tab: this.tab },
        }).href
      );
    },

    onBookingChanged() {
      // this.spotListrenderKey++;
      // this.$refs.spotList.searchCriteria = this.searchCriteria ;
      this.$refs.cart.countDraftSalesOrder();
      this.$refs.spotList.getList();
    },

    // region check in/out
    async checkInBooking(evt) {
      let booking = evt.booking;
      let salesInvoice = evt.invoice;

      try {
        // La facture n'est pas envoyee, on va la chercher
        if (!salesInvoice) {
          swal.showLoading();

          await this.$store.dispatch("salesInvoices/list", {
            filter: {
              salesOrder: booking.orderItem.salesOrder.id,
            },
          });

          let invoices = this.$store.getters["salesInvoices/list"];

          swal.close();
          if (invoices?.length) {
            salesInvoice = invoices[0];
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("SALES_INVOICES.SALES_INVOICE_NOT_FOUND"),
            });

            return;
          }
        }
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        return;
      }

      let swalConfig = {
        title: this.$t("BOOKINGS.CHECK_IN_THIS_BOOKING"),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      };

      // Il y a des restes à payer
      if (salesInvoice.total_remaining_payment) {
        swalConfig.html = this.$t(
          "SALES_INVOICES.SALES_INVOICE_REMAINING_PAYMENT",
          [
            this.$formatCurrency(salesInvoice.total_remaining_payment),
            this.$router.resolve({
              name: "List SalesInvoices",
              query: {
                id: salesInvoice.id,
                action: QUERY_ACTIONS_VIEW,
              },
            }).href,
          ]
        );
      }

      const confirmation = await swal.fire(swalConfig);

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch("bookings/checkIn", booking.id);
          this.renderKey++;
          // this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_CHECKED_IN"),
          });
        }
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkOutBooking(evt) {
      let booking = evt.booking;
      let salesInvoice = evt.invoice;

      try {
        // La facture n'est pas envoyee, on va la chercher
        if (!salesInvoice) {
          swal.showLoading();

          await this.$store.dispatch("salesInvoices/list", {
            filter: {
              salesOrder: booking.orderItem.salesOrder.id,
            },
          });

          let invoices = this.$store.getters["salesInvoices/list"];

          swal.close();
          if (invoices?.length) {
            salesInvoice = invoices[0];
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("SALES_INVOICES.SALES_INVOICE_NOT_FOUND"),
            });

            return;
          }
        }
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        return;
      }

      let swalConfig = {
        title: this.$t("BOOKINGS.CHECK_OUT_THIS_BOOKING"),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      };

      // Il y a des restes à payer ou a rembourser
      if (salesInvoice?.total_remaining_payment > 0) {
        swalConfig.html = this.$t(
          "SALES_INVOICES.SALES_INVOICE_REMAINING_PAYMENT",
          [
            this.$formatCurrency(salesInvoice.total_remaining_payment),
            this.$router.resolve({
              name: "List SalesInvoices",
              query: {
                id: salesInvoice.id,
                action: QUERY_ACTIONS_VIEW,
              },
            }).href,
          ]
        );
      } else if (salesInvoice?.total_remaining_payment < 0) {
        swalConfig.html = this.$t("SALES_INVOICES.SALES_INVOICE_DUE_PAYMENT", [
          this.$formatCurrency(-salesInvoice.total_remaining_payment),
          this.$router.resolve({
            name: "List SalesInvoices",
            query: {
              id: salesInvoice.id,
              action: QUERY_ACTIONS_VIEW,
            },
          }).href,
        ]);
      }

      const confirmation = await swal.fire(swalConfig);

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch("bookings/checkOut", booking.id);
          this.renderKey++;
          // this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_CHECKED_OUT"),
          });
        }
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    // endregion

    // region cancel booking
    /**
     * Annuler une reservation.
     *
     * @author jordy jordy[at]latactik.com
     *
     * @param cancelEvt {booking: Object|null, invoice: Object|null}
     * @returns {Promise<void>}
     */
    async cancelBooking(cancelEvt)
    {
      let booking = cancelEvt.booking;
      let salesInvoice = cancelEvt.invoice;

      try
      {
        // La facture n'est pas envoyee, on va la chercher
        if (!salesInvoice)
        {
          swal.showLoading();

          await this.$store.dispatch("salesInvoices/list", {
            filter: {
              salesOrder: booking.orderItem.salesOrder.id,
            },
          });

          let invoices = this.$store.getters["salesInvoices/list"];

          swal.close();
          if (invoices?.length) {
            salesInvoice = invoices[0];
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("SALES_INVOICES.SALES_INVOICE_NOT_FOUND"),
            });

            return;
          }
        }
      }
      catch (error)
      {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        return;
      }

      // get sales_order_item
      let invoiceItem = salesInvoice.items.filter(item => item.salesInvoiceable.id === booking.id)[0] ;

      let swalConfig = {
        title: this.$t("BOOKINGS.CANCEL_THIS_BOOKING"),
        type: "question",
        html: '',
        customClass: {
          popup: "default-popup warning",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      };

      // il va s'appliquer des frais d'annulations
      if(invoiceItem.cancellation_fields.fees)
      {
        // alerte
        swalConfig.html = `<span>${this.$t("BOOKINGS.CANCELLATION_WITH_FEES", [
          this.$router.resolve({name: "Policy Page", query: {id: booking.policy.id, action: QUERY_ACTIONS_VIEW}}).href
        ])}</span><br/><br/>` ;
        // affichage des frais d'annulation
        swalConfig.html += `<span>${this.$t("BOOKINGS.CANCEL_FEE", [this.$formatCurrency(invoiceItem.cancellation_fields.fees)])}</span><br/><br/>` ;
      }
      // Solde ou a rembourser
      if(invoiceItem.cancellation_fields.cancelled_amount)
      {
        let amount = Math.abs(invoiceItem.cancellation_fields.cancelled_amount) ;
        let refund = invoiceItem.cancellation_fields.cancelled_amount < 0 ;
        // remboursement
        if(refund) {
          swalConfig.html += `<span>${this.$t("BOOKINGS.REFUND_AMOUNT", [this.$formatCurrency(amount)])}</span><br/>` ;
        }
        // solde du
        else {
          swalConfig.html += `<span>${this.$t("BOOKINGS.BALANCE_OUTSTANDING_AMOUNT", [this.$formatCurrency(amount)])}<br/><br/>` ;
        }
      }

      try
      {
        const confirmation = await swal.fire(swalConfig);
        if (confirmation.isConfirmed)
        {
          swal.showLoading();
          await this.$store.dispatch("bookings/cancel", booking.id);
          this.renderKey++;
          // this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_CANCELLED"),
          });
        }
      }
      catch (error)
      {
        console.error(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      finally {
        swal.close();
      }
    },
    // endregion

    // region Edit booking methods
    editBookingFromView(booking) {
      this.openEditBookingModal({
        id: booking.orderItem.salesOrder.id,
        bookingId: booking.id,
      });
    },

    openEditBookingModal(salesOrder, reRender = false) {
      this.closeBookingModal();
      this.openOrder = {
        id: salesOrder.id,
      };

      this.openBooking = {
        id: salesOrder.bookingId,
      };

      this.isEditBookingModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query: {
            orderId: this.openOrder.id,
            id: this.openBooking.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },

    handleAfterBookingUpdated() {
      this.isBookingUpdated = true;
    },
    // endregion
  },

  watch: {
    tab(value) {
      const query = this.$route.query;

      query.tab = value;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query,
        }).href
      );
    },
    "$route.query.tab": {
      handler: function (value) {
        this.tab = value;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
