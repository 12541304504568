<template>
  <div class="container-fluid">
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left booking">
          <div class="kw-back">
            <h3>{{ $t("BOOKINGS.BOOKING_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.BOOKING_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="page-wrapper-header-right">
          <router-link
            v-if="$currentUserCan($permissions.PERM_CREATE_BOOKINGS)"
            :to="{ name: 'New Booking' }"
            v-slot="{ href, navigate }"
            custom
          >
            <base-button
              :href="href"
              @click="navigate"
              class="elite-button add"
              icon
              size="sm"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("BOOKINGS.ADD_BOOKING") }}
              </span>
            </base-button>
          </router-link>
        </div>
      </div>

      <booking-list-table
        @onViewBooking="openBookingViewModal"
        @onDeleteBooking="deleteBooking"
        @onCheckOutBooking="checkOutBooking"
        @onCheckInBooking="checkInBooking"
        :key="renderKey * 100"
      />

      <!-- region paiement -->
      <div
        v-if="isConfirmBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isConfirmBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeBookingModal(false)">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.CONFIRM_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button />
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <confirm-booking-component
              v-if="openOrder"
              :sales-order-id="openOrder.id"
              @onCloseBookingModal="closeBookingModal"
            />
          </div>
        </div>
      </div>
      <!-- endregion -->

      <!-- region view booking modal -->
      <div
        v-if="isViewBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'BOOKING_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeBookingModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>{{ $t("BOOKINGS.VIEW_BOOKING") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-booking-component
              v-if="openBooking"
              :key="renderKey * 300"
              :bookingId="openBooking.id"
              @onEditBooking="onEditBooking"
              @onCheckOutBooking="checkOutBooking"
              @onBookingViewing="onBookingViewing"
              @onCheckInBooking="checkInBooking"
              @onCancellingBooking="cancelBooking"
              @onOpenConfirmBookingModal="onOpenConfirmBookingModal"
            />
          </div>
        </div>
      </div>
      <!-- endregion -->

      <!-- region edit booking modal -->
      <div
        v-if="isEditBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="backFromEditionToView">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.EDIT_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-booking-component
              v-if="openBooking"
              :sales-order-id="selectedBookingOrderId"
              :booking-id="openBooking.id"
              @bookingUpdated="handleAfterBookingUpdated"
              @customerUpdated="handleAfterCustomerUpdated"
              @onSalesOrderEditionCancelled="backFromEditionToView"
              @onOpenConfirmBookingModal="onOpenConfirmBookingModal"
            />
          </div>
        </div>
      </div>
      <!-- endregion -->

      <div
        v-if="isAddBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("BOOKINGS.ADD_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeBookingModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <!--<add-booking-component @onViewBooking="openBookingViewModal" />-->
          </div>
        </div>
      </div>
    </div>
    <select-an-organization-message v-else />
  </div>
</template>
<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  BOOKING_STATUS_GUARANTEED,
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_CONFIRM,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import BookingListTable from "./partials/BookingListTable.vue";
import EditBookingComponent from "./components/EditBookingComponent.vue";
import ViewBookingComponent from "./components/ViewBookingComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";
import { mapGetters } from "vuex";
import ConfirmBookingComponent from "@/views/Pages/BookingModule/BookingManagement/components/ConfirmBookingComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ConfirmBookingComponent,
    SelectAnOrganizationMessage,
    BookingListTable,
    EditBookingComponent,
    ViewBookingComponent,
    [Button.name]: Button,
    WrapperExpandButton,
    //AddBookingComponent,
    // NotificationSubscription,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const bookingId = this.$route.query.id;
    const action = this.$route.query.action;
    const orderId = this.$route.query.orderId;

    let isViewBookingModalOpened = false;
    let isEditBookingModalOpened = false;
    let isAddBookingModalOpened = false;
    let isConfirmBookingModalOpened = false;
    let openBooking = null;
    let openOrder = null;

    openOrder = { id: orderId };

    if (bookingId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewBookingModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditBookingModalOpened = true;
      }
      openBooking = { id: bookingId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddBookingModalOpened = true;
    } else if (action === QUERY_ACTIONS_CONFIRM) {
      isConfirmBookingModalOpened = true;

      openOrder = { id: orderId };
    }

    return {
      isBookingUpdated: false,
      selectedBookingOrderId: null,
      isConfirmBookingModalOpened,
      isViewBookingModalOpened: isViewBookingModalOpened,
      isEditBookingModalOpened: isEditBookingModalOpened,
      isAddBookingModalOpened: isAddBookingModalOpened,
      openBooking: openBooking,
      renderKey: 1,
      openOrder,
    };
  },

  methods: {
    openBookingCreateModal() {
      this.closeBookingModal();
      this.isAddBookingModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openBookingViewModal(booking, reRender = false) {
      this.closeBookingModal();
      this.openBooking = booking;
      this.isViewBookingModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: { id: this.openBooking.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    onBookingViewing(salesOrderItem) {
      this.selectedBookingOrderId = salesOrderItem;
    },
    onEditBooking() {
      this.openBookingEditModal(this.openBooking);
    },
    openBookingEditModal(booking) {
      this.openBooking = booking;
      this.isEditBookingModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: { id: this.openBooking.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeBookingModal(reRender = false) {
      this.isAddBookingModalOpened = false;
      this.isViewBookingModalOpened = false;
      this.isEditBookingModalOpened = false;
      this.isConfirmBookingModalOpened = false;
      this.openBooking = null;
      this.openOrder = null;
      this.selectedBookingOrderId = null;

      if (reRender || this.isBookingUpdated) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: {},
        }).href
      );
    },

    backFromEditionToView() {
      this.isAddBookingModalOpened = false;
      this.isViewBookingModalOpened = true;
      this.isEditBookingModalOpened = false;
      if (this.isBookingUpdated) {
        this.renderKey++;
        this.isBookingUpdated = false;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: { id: this.openBooking.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    async deleteBooking(booking) {
      const confirmation = await swal.fire({
        title: this.$t("BOOKINGS.DELETE_THIS_BOOKING"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("bookings/destroy", booking.id);
          this.renderKey++;
          this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    // region check-in/out
    async checkInBooking(evt) {
      let booking = evt.booking;
      let salesInvoice = evt.invoice;

      try {
        // La facture n'est pas envoyee, on va la chercher
        if (!salesInvoice) {
          swal.showLoading();

          await this.$store.dispatch("salesInvoices/list", {
            filter: {
              salesOrder: booking.orderItem.salesOrder.id,
            },
          });

          let invoices = this.$store.getters["salesInvoices/list"];

          swal.close();
          if (invoices?.length) {
            salesInvoice = invoices[0];
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("SALES_INVOICES.SALES_INVOICE_NOT_FOUND"),
            });

            return;
          }
        }
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        return;
      }

      let swalConfig = {
        title: this.$t("BOOKINGS.CHECK_IN_THIS_BOOKING"),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      };

      // Il y a des restes à payer ou a rembourser
      if (salesInvoice?.total_remaining_payment > 0) {
        swalConfig.html = this.$t(
          "SALES_INVOICES.SALES_INVOICE_REMAINING_PAYMENT",
          [
            this.$formatCurrency(salesInvoice.total_remaining_payment),
            this.$router.resolve({
              name: "List SalesInvoices",
              query: {
                id: salesInvoice.id,
                action: QUERY_ACTIONS_VIEW,
              },
            }).href,
          ]
        );
      } else if (salesInvoice?.total_remaining_payment < 0) {
        swalConfig.html = this.$t("SALES_INVOICES.SALES_INVOICE_DUE_PAYMENT", [
          this.$formatCurrency(-salesInvoice.total_remaining_payment),
          this.$router.resolve({
            name: "List SalesInvoices",
            query: {
              id: salesInvoice.id,
              action: QUERY_ACTIONS_VIEW,
            },
          }).href,
        ]);
      }

      const confirmation = await swal.fire(swalConfig);

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch("bookings/checkIn", booking.id);
          this.renderKey++;
          // this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_CHECKED_IN"),
          });
        }
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkOutBooking(evt) {
      let booking = evt.booking;
      let salesInvoice = evt.invoice;

      try {
        // La facture n'est pas envoyee, on va la chercher
        if (!salesInvoice) {
          swal.showLoading();

          await this.$store.dispatch("salesInvoices/list", {
            filter: {
              salesOrder: booking.orderItem.salesOrder.id,
            },
          });

          let invoices = this.$store.getters["salesInvoices/list"];

          swal.close();
          if (invoices?.length) {
            salesInvoice = invoices[0];
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("SALES_INVOICES.SALES_INVOICE_NOT_FOUND"),
            });

            return;
          }
        }
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        return;
      }

      let swalConfig = {
        title: this.$t("BOOKINGS.CHECK_OUT_THIS_BOOKING"),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      };

      // Il y a des restes à payer ou a rembourser
      if (salesInvoice?.total_remaining_payment > 0) {
        swalConfig.html = this.$t(
          "SALES_INVOICES.SALES_INVOICE_REMAINING_PAYMENT",
          [
            this.$formatCurrency(salesInvoice.total_remaining_payment),
            this.$router.resolve({
              name: "List SalesInvoices",
              query: {
                id: salesInvoice.id,
                action: QUERY_ACTIONS_VIEW,
              },
            }).href,
          ]
        );
      } else if (salesInvoice?.total_remaining_payment < 0) {
        swalConfig.html = this.$t("SALES_INVOICES.SALES_INVOICE_DUE_PAYMENT", [
          this.$formatCurrency(-salesInvoice.total_remaining_payment),
          this.$router.resolve({
            name: "List SalesInvoices",
            query: {
              id: salesInvoice.id,
              action: QUERY_ACTIONS_VIEW,
            },
          }).href,
        ]);
      }

      const confirmation = await swal.fire(swalConfig);

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch("bookings/checkOut", booking.id);
          this.renderKey++;
          // this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_CHECKED_OUT"),
          });
        }
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    // endregion

    // region cancel booking
    /**
     * Annuler une reservation.
     *
     * @author jordy jordy[at]latactik.com
     *
     * @param cancelEvt {booking: Object|null, invoice: Object|null}
     * @returns {Promise<void>}
     */
    async cancelBooking(cancelEvt)
    {
      let booking = cancelEvt.booking;
      let salesInvoice = cancelEvt.invoice;

      try
      {
        // La facture n'est pas envoyee, on va la chercher
        if (!salesInvoice)
        {
          swal.showLoading();

          await this.$store.dispatch("salesInvoices/list", {
            filter: {
              salesOrder: booking.orderItem.salesOrder.id,
            },
          });

          let invoices = this.$store.getters["salesInvoices/list"];

          swal.close();
          if (invoices?.length) {
            salesInvoice = invoices[0];
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("SALES_INVOICES.SALES_INVOICE_NOT_FOUND"),
            });

            return;
          }
        }
      }
      catch (error)
      {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        return;
      }

      // get sales_order_item
      let invoiceItem = salesInvoice.items.filter(item => item.salesInvoiceable.id === booking.id)[0] ;

      let swalConfig = {
        title: this.$t("BOOKINGS.CANCEL_THIS_BOOKING"),
        type: "question",
        html: '',
        customClass: {
          popup: "default-popup warning",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      };

      // il va s'appliquer des frais d'annulations
      if(invoiceItem.cancellation_fields.fees)
      {
        // alerte
        swalConfig.html = `<span>${this.$t("BOOKINGS.CANCELLATION_WITH_FEES", [
          this.$router.resolve({name: "Policy Page", query: {id: booking.policy.id, action: QUERY_ACTIONS_VIEW}}).href
        ])}</span><br/><br/>` ;
        // affichage des frais d'annulation
        swalConfig.html += `<span>${this.$t("BOOKINGS.CANCEL_FEE", [this.$formatCurrency(invoiceItem.cancellation_fields.fees)])}</span><br/><br/>` ;
      }
      // Solde ou a rembourser
      if(invoiceItem.cancellation_fields.cancelled_amount)
      {
        let amount = Math.abs(invoiceItem.cancellation_fields.cancelled_amount) ;
        let refund = invoiceItem.cancellation_fields.cancelled_amount < 0 ;
        // remboursement
        if(refund) {
          swalConfig.html += `<span>${this.$t("BOOKINGS.REFUND_AMOUNT", [this.$formatCurrency(amount)])}</span><br/>` ;
        }
        // solde du
        else {
          swalConfig.html += `<span>${this.$t("BOOKINGS.BALANCE_OUTSTANDING_AMOUNT", [this.$formatCurrency(amount)])}<br/><br/>` ;
        }
      }

      try
      {
        const confirmation = await swal.fire(swalConfig);
        if (confirmation.isConfirmed)
        {
          swal.showLoading();
          await this.$store.dispatch("bookings/cancel", booking.id);
          this.renderKey++;
          // this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_CANCELLED"),
          });
        }
      }
      catch (error)
      {
        console.error(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      finally {
        swal.close();
      }
    },
    // endregion

    onOpenConfirmBookingModal(order, reRender = false) {
      this.closeBookingModal();
      this.openOrder = order;
      this.isConfirmBookingModalOpened = true;

      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: { orderId: this.openOrder.id, action: QUERY_ACTIONS_CONFIRM },
        }).href
      );
    },

    handleAfterBookingUpdated(booking) {
      this.openBooking = cloneDeep(booking);
      this.isBookingUpdated = true;
    },

    handleAfterCustomerUpdated() {
      this.isBookingUpdated = true;
    },
  },
};
</script>
